import { observable, autorun, action } from "mobx";

window.timeDifference = 0;

let logoutTimer;

class user {
  @observable isLogin = false;
  @observable systemConfig = {};
  @observable userInfo = {};
  @observable appInfo = {};
  // 布控一体机导入文件成功路径
  @observable filePath = "";
  // 获取系统时间的误差
  @observable timeDifference = 0;

  @observable userHabit = {
    faceModel: "normal",
    bodyModel: "normal",
    vehicleModel: "normal",
    noVehicleModel: "normal",
    accessModel: "normal",
    personnelCardModel: "normal"
  };

  /**
   * 获取系统服务器时间
   */
  get systemTime() {
    return Date.now() - this.timeDifference;
  }

  @action
  setUserHabit(data) {
    let userHabit = this.userHabit;
    Object.keys(data).forEach(k => {
      if (userHabit[k] !== undefined) {
        userHabit[k] = data[k];
      }
    });
    this.userHabit = userHabit;
    Utils.setCache("userHabit", userHabit, "local");
  }

  @action
  updateFilePath(filePath) {
    this.filePath = filePath;
  }

  @action
  clearStore() {
    this.timeDifference = 0;
    this.isLogin = false;
    this.systemConfig = {};
    this.appInfo = {};
    sessionStorage.removeItem("isLogin");
    sessionStorage.removeItem("userInfo");
    sessionStorage.removeItem("systemConfig");
    sessionStorage.removeItem("appInfo");
  }

  /**
   * 修改登陆状态
   * @param {Boolean} flag
   */
  @action
  setLoginState(flag) {
    this.isLogin = flag;
    Utils.setCache("isLogin", flag, "session");
  }

  /**
   * 更新用户信息
   * @param {Object} userInfo
   */
  @action
  setUserInfo(userInfo) {
    this.userInfo = userInfo;
    Utils.setCache("userInfo", userInfo, "session");
  }

  /**
   * 获取系统信息
   * @param {} info
   */
  @action
  setSystemConfig(info) {
    if (info.systemName) {
      document.title = info.systemName;
    }
    this.systemConfig = info;
    Utils.setCache("systemConfig", info, "session");
  }

  @action
  updateUserAvatar(url) {
    this.userInfo.userAvatarUrl = url;
  }

  /**
   * 获取运营中心信息
   * @param {*} info
   */
  @action
  setCenterInfo(info) {
    this.appInfo = info;
    Utils.setCache("appInfo", info, "session");
  }

  @action
  setSystemTimeDef(num) {
    if (this.timeDifference === 0 || num < this.timeDifference * 2) {
      window.timeDifference = this.timeDifference = num;
    }
  }

  @action
  logout({ isRedirect, isLogin }) {
    if (!window.ReactHistory) {
      return;
    }
    isLogin
      ? Service.user.loginOut().then(() => this.jumpLoginPage(isRedirect))
      : this.jumpLoginPage(isRedirect);
  }

  jumpLoginPage(isRedirect) {
    clearTimeout(logoutTimer);
    logoutTimer = setTimeout(() => {
      const { pathname, search } = window.ReactHistory.location;
      BaseStore.user.clearStore();
      let loginType = Utils.getCache("loginType", "session") || "";
      const url = encodeURIComponent(`${pathname}${search}`);
      if (loginType && loginType !== "undefined") {
        window.ReactHistory.replace(
          `/login/${loginType}${isRedirect ? `?redirect=${url}` : ""}`
        );
      } else {
        window.ReactHistory.replace(
          `/login${isRedirect ? `?redirect=${url}` : ""}`
        );
      }
      BaseStore.device.clearStore();
      BaseStore.organization.clearStore();
      BaseStore.tab.clearStore();
      BaseStore.menu.clearStore();
      sessionStorage.clear();
      window.videoCameraMap = {};

    }, 500);
  }

  constructor() {
    autorun(() => {
      const isLogin = Utils.getCache("isLogin", "session");
      const userInfo = Utils.getCache("userInfo", "session") || {};
      const appInfo = Utils.getCache("appInfo", "session") || {};
      const systemConfig = Utils.getCache("systemConfig", "session") || {};
      const userHabit = Utils.getCache("userHabit", "local") || {};

      this.setLoginState(!!isLogin);
      this.setUserInfo(userInfo);
      this.setCenterInfo(appInfo);
      this.setSystemConfig(systemConfig);
      this.setUserHabit(userHabit);
    });
  }
}
export default new user();
