import { observable, action, computed, toJS, runInAction } from 'mobx'
import OrganizationStore from './organization'
import PlaceStore from './place'
import * as _ from 'lodash'



let deviceIdMap = {};
let deviceCIdMap = {};
const placeDeviceMap = {};

function filterCamera(v) {
  const { znxj, qj, zpj, db, clzpj } = Dict.map;
  return v.deviceType === qj.value || v.deviceType === znxj.value || v.deviceType === zpj.value || v.deviceType === db.value || v.deviceType === clzpj.value
}

class device {
  streamKVKey = "DEVICE_STREAM";
  @observable updateTime = 0;
  @observable streamPreference = []; // 设备开流偏好(实时优先flv)
  @action setStreamPreference(streamPreference) {
    this.streamPreference = streamPreference
    return Promise.resolve(streamPreference)
  }

  @computed get deviceList() {
    console.warn('设备最近更新时间->', this.updateTime);
    return _.cloneDeep(Object.values(deviceIdMap))
  }

  @computed get deviceArray() {
    console.warn('设备最近更新时间->', this.updateTime);
    return _.cloneDeep(Object.values(deviceIdMap))
  }

  @computed get cameraList() {
    console.warn('设备最近更新时间->', this.updateTime);
    const list = Object.values(deviceIdMap).filter(filterCamera);
    return _.cloneDeep(list)
  }

  @action updateDepHolder() {
    console.warn('设备依赖已更新！')
    this.updateTime = Date.now()
  }

  @action setDeviceMap(idMap, cidMap) {
    deviceIdMap = idMap;
    deviceCIdMap = cidMap;
    this.updateTime = Date.now()
  }

  // 在所有设备里查询
  getDeviceById(id) {
    return _.cloneDeep(deviceIdMap[id])
  }

  // 在所有设备里查询
  getDeviceByIds(ids) {
    return _.compact(ids.map(id => this.getDeviceById(id)))
  }

  // 在所有设备里查询
  getDeviceByCId(id) {
    return _.cloneDeep(deviceCIdMap[id])
  }

  // 在所有设备里查询
  getDeviceByCIds(ids) {
    return _.compact(ids.map(id => this.getDeviceByCId(id)))
  }

  getDeviceByIdOrCid(id) {
    return this.getDeviceByCId(id) || this.getDeviceById(id)
  }

  getListByIdsOrCids(ids) {
    return _.compact(
      ids.map(id => {
        const item = this.getDeviceByCId(id)
        return item ? item : this.getDeviceById(id)
      })
    )
  }

  /**
   * 查询组织下摄像机列表(含子组织)
   * @param {string} orgId
   */
  queryCameraByIncludeOrgId(orgId) {
    let orgIds = OrganizationStore.queryOrgIdsForParentOrgId(orgId)
    const cameraList = this.cameraList.filter(item => {
      let flag = false
      if (!Array.isArray(item.organizationIds)) {
        item.organizationIds = []
      }
      for (let i = 0, l = item.organizationIds.length; i < l; i++) {
        if (orgIds.indexOf(item.organizationIds[i]) > -1) {
          flag = true
          break
        }
      }
      return flag
    })
    return cameraList
  }

  /**
   * 查询组织下设备列表(含子组织)
   * @param {string} orgId
   */
  queryDeviceByIncludeOrgId(orgId) {
    let orgIds = OrganizationStore.queryOrgIdsForParentOrgId(orgId)
    const deviceList = this.deviceList.filter(item => {
      let flag = false
      if (!Array.isArray(item.organizationIds)) {
        item.organizationIds = []
      }
      for (let i = 0, l = item.organizationIds.length; i < l; i++) {
        if (orgIds.indexOf(item.organizationIds[i]) > -1) {
          flag = true
          break
        }
      }
      return flag
    })
    return deviceList
  }

  /**
   * 查询场所下设备列表(含子组织)
   * @param {string} orgId
   */
  queryCameraByIncludePlaceId(id) {
    let placeIds = PlaceStore.getPlaceCodesWithChild(id)
    const cameraList = this.cameraList.filter(item => placeIds.indexOf(item.placeId) > -1)
    return cameraList
  }

  /**
   * 获取无权限的设备
   * @param {*} cid
   */
  async getDeviceForPlaceAuth(cid) {
    if (placeDeviceMap[cid]) {
      return placeDeviceMap[cid]
    }
    const { Service } = window
    let data
    try {
      const result = await Service.device.getDeviceByCId1(cid)
      data = placeDeviceMap[cid] = result.data
    } catch (e) {
      console.error(e)
    }
    return data
  }

  @action
  clearStore() {
    deviceIdMap = {}
    deviceCIdMap = {}
  }

  getUpdateInfo(info) {
    let option = {
      type: null, // add , update ,delete
      data: null,
      index: -1
    }
    if (!info.deviceId) {
      return option
    }

    if (!deviceIdMap[info.deviceId] && info.type !== 9) {
      return option
    }
    if (!deviceIdMap[info.deviceId] && info.type === 9) {
      const {
        deviceId,
        cid,
        deviceName,
        deviceStatus,
        deviceType,
        latitude,
        longitude,
        lygroupIndex,
        manufacturerDeviceId,
        manufacturerDeviceType,
        toOrganizationId,
        pathId,
        placeId,
        sn
      } = info
      const newDevice = {
        cid,
        deviceName,
        deviceStatus,
        deviceType,
        id: deviceId,
        latitude,
        longitude,
        lygroupIndex,
        manufacturerDeviceId,
        manufacturerDeviceType,
        organizationIds: [toOrganizationId],
        pathId,
        placeId,
        sn
      }
      option.type = 'add'
      option.data = newDevice
      return option
    }
    option.id = info.deviceId
    option.data = _.cloneDeep(deviceIdMap[info.deviceId])
    switch (info.type) {
      case 1: //设备名称变化
        option.type = 'update'
        option.data.deviceName = info.deviceName
        break
      case 2: //设备状态变化
        option.type = 'update'
        option.data.deviceStatus = info.deviceStatus
        break
      case 3: //设备位置变化
        option.type = 'update'
        option.data.longitude = info.longitude
        option.data.latitude = info.latitude
        break
      case 4: //设备移除变化
      case 7:
        option.type = 'delete'
        break
      case 8: //设备所属场所变化
        option.type = 'update'
        option.data.placeId = info.placeId
        if (info.longitude && info.latitude) {
          option.data.longitude = info.longitude
          option.data.latitude = info.latitude
        }

        break
      case 9: //设备组织变化
        if (OrganizationStore.getOrgInfoByOrgId(info.toOrganizationId)) {
          option.type = 'update'
          const orgIds = [info.toOrganizationId]
          option.data.organizationIds = orgIds
        } else {
          option.type = 'delete'
        }
        break
      default:
        return
    }
    return option
  }

  /**
   * 更新设备信息
   * @param {object} info
   */

  updateDevice(info) {
    const option = this.getUpdateInfo(info)
    if (!option.type || !option.data) {
      return
    }
    if (option.type === 'add') {
      deviceIdMap[option.id] = option.data
    }
    if (option.type === 'update') {
      deviceIdMap[option.id] = option.data
    }
    if (option.type === 'delete') {
      delete deviceIdMap[option.id]
      delete deviceCIdMap[option.data.cid]
    }
    this.updateDepHolder()
  }

  updateDevices(list = [], callback) {
    let date = Date.now()
    let arr = []
    for (let i = 0; i < list.length; i++) {
      const info = list[i]
      const option = this.getUpdateInfo(info)
      if (!option.type || !option.data) {
        return
      }
      if (option.type === 'add') {
        deviceIdMap[option.id] = option.data
        deviceCIdMap[option.data.cid] = option.data
      }
      if (option.type === 'update') {
        deviceIdMap[option.id] = option.data
        deviceCIdMap[option.data.cid] = option.data
      }
      if (option.type === 'delete') {
        delete deviceIdMap[option.id]
        delete deviceCIdMap[option.data.cid]
      }
      arr.push(option)
    }
    console.warn(`设备更新耗时 -> ${Date.now() - date}ms`)
    this.updateDepHolder()
    callback && callback(arr)
  }

  /*----------------------------------------------------------------*/
}
export default new device()
